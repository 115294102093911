import Vue from 'vue'
import pluralize from 'plural-ru'
import { orderStatuses } from '../utils/constants'

Vue.filter('time', function(value, is24HrFormat = false) {
	if(value) {
		const date = new Date(Date.parse(value));
		let hours = date.getHours();
		const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
		if(!is24HrFormat) {
			const time = hours > 12 ? 'AM' : 'PM';
			hours = hours % 12 || 12;
			return hours + ':' + min + ' ' + time
		}
		return hours + ':' + min
	}
})

Vue.filter('date', function(value, fullDate = false) {
	value = String(value)
	const date = value.slice(8,10).trim();
	const month = value.slice(4,7).trim();
	const year = value.slice(11,15);

	if(!fullDate) return date + ' ' + month;
	else return date + ' ' + month + ' ' + year;
})

Vue.filter('pluralize', function (num, ...args) {
	return pluralize(num, ...args)
})

Vue.filter('orderStatusToText', function (val) {
  return orderStatuses[val] || orderStatuses.searching
})

Vue.filter('valueOrZero', function (val) {
  return val ? +val : 0
})

Vue.filter('insertDot', function (str) {
  return str ? Number(str) / 100 : 0
})

Vue.filter('formatPrice', function (price) {
  const value = Number(price)
  return value ? (value / 100).toFixed(2) : 0
})

Vue.filter('getOrderPrice', function (order) {
  const isVat = order.isVat
  let price = 0
  if (order.services.length) {
    price += order?.services?.reduce((a, s) => a + s.pricesClient[isVat ? 'priceVat' : 'price'], 0)
  }
  if (order.extraCosts.length) {
    price += order?.extraCosts?.reduce((a, s) => a + s.price, 0)
  }
  if (order.tariffPricesClient) {
    price += order.tariffPricesClient[isVat ? 'minPriceVat' : 'minPrice'] ?? 0
    price += (order.tariffPricesClient[isVat ? 'extraHourPriceVat' : 'extraHourPrice'] * (order.extraHourCount ?? 0))
    price += (order.tariffPricesClient[isVat ? 'extraKmPriceVat' : 'extraKmPrice'] * (order.extraKmCount ?? 0))
  }

  return price ? Number(price) / 100 : 0
})
